import { Box, Typography, useTheme } from '@mui/material';
import { TextHighlighter } from '@ysura/common';
import { styled } from 'styled-components';

type PreferenceFacetItemCardProps = {
  name: string;
  query?: string;
};

export const PreferenceFacetItemCard = ({
  query,
  name,
}: PreferenceFacetItemCardProps) => {
  const theme = useTheme();

  return (
    <Row data-testid="preference-facet-item-card">
      <Typography
        variant="subtitle2"
        color={theme.palette.text.primary}
        data-testid="preference-facet-item-name"
      >
        <TextHighlighter
          searchQueryArray={query?.split(' ') ?? []}
          text={name}
        />
      </Typography>
    </Row>
  );
};

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
