import {
  Email as EmailIcon,
  LocalHospital,
  MedicalServices,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import {
  Box,
  List as MuiList,
  ListItem as MuiListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CommunicationDataBaseTypeDataEnum,
  EmploymentData,
  IconWrapper,
  Label,
  OverviewCard,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { AddressBox } from '@/components/Address';
import { LabelsContainer } from '@/components/LabelsContainer';
import { LinkNoDecoration } from '@/components/LinkTo';
import { useCurrentUser } from '@/hooks';
import { getAddress } from '@/services/employment';

type OrganizationCardProps = {
  employment: EmploymentData;
  isInsideAccordion?: boolean;
  shouldBeNavigateToOrganization?: boolean;
};

export const OrganizationCard = ({
  employment,
  isInsideAccordion = false,
  shouldBeNavigateToOrganization = true,
}: OrganizationCardProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const theme = useTheme();
  const { communicationData, employmentFunction, department, organization } =
    employment;

  const address = getAddress(employment);

  const isContactInfoAvailable =
    communicationData && communicationData.length > 0;
  const multipleOrgNamesExist =
    organization?.rootOrganization?.name && organization?.name;

  const content = (
    <Wrapper>
      <ParentStack>
        <ChildStack>
          <StyledBox>
            <IconWrapper size={20}>
              <LocalHospital fontSize="small" />
            </IconWrapper>
            <Typography variant="subtitle2">
              {organization?.rootOrganization?.name || organization?.name}
            </Typography>
          </StyledBox>

          {employmentFunction?.name && (
            <LabelsContainer>
              <Label data-testid="role-label" variant="filled">
                {employmentFunction.name}
              </Label>
            </LabelsContainer>
          )}
        </ChildStack>

        {(department?.name || multipleOrgNamesExist) && (
          <ChildStack>
            {/* Render employmentOrg only if parentOrg was rendered above */}
            {multipleOrgNamesExist && (
              <StyledBox data-testid="employment-organization-name">
                <IconWrapper size={13}>
                  <EmploymentOrganizationNameIcon fontSize="small" />
                </IconWrapper>
                <TextWithEllipsis variant="body2">
                  {organization?.name}
                </TextWithEllipsis>
              </StyledBox>
            )}

            {department?.name && (
              <TextWithEllipsis
                variant="caption"
                data-testid="employment-department"
                color={theme.palette.text.disabled}
              >
                {department.name}
              </TextWithEllipsis>
            )}
          </ChildStack>
        )}

        <AddressBox address={address} />
      </ParentStack>
      {isContactInfoAvailable && (
        <Stack>
          <DisableTypography variant="caption">
            {t('pages.personView.contactInformation')}
          </DisableTypography>
          <List>
            {communicationData.map((contact, idx) => (
              <ListItem
                key={`contact-info-${idx}`}
                divider={idx < communicationData.length - 1}
                data-testid="contact-list-item"
              >
                <Stack>
                  <Typography variant="caption">
                    {contact?.communicationDataType?.name}
                  </Typography>
                  <DisableTypography variant="caption">
                    {contact?.value}
                  </DisableTypography>
                </Stack>

                <IconsContainer>
                  {contact?.communicationDataType?.baseType ===
                    CommunicationDataBaseTypeDataEnum.EMAIL && (
                    <IconWrapper data-testid="icon-email">
                      <EmailIcon color="primary" fontSize="small" />
                    </IconWrapper>
                  )}

                  {currentUser?.permissions?.common?.canMakePhoneCall &&
                    contact?.communicationDataType?.baseType ===
                      CommunicationDataBaseTypeDataEnum.PHONE && (
                      <IconWrapper data-testid="icon-phone">
                        <a href={'tel:' + contact?.value}>
                          <PhoneIcon color="primary" fontSize="small" />
                        </a>
                      </IconWrapper>
                    )}
                </IconsContainer>
              </ListItem>
            ))}
          </List>
        </Stack>
      )}
    </Wrapper>
  );

  const linkContent = (
    <LinkNoDecoration to={`/organization/${organization?.id}`}>
      {content}
    </LinkNoDecoration>
  );

  if (isInsideAccordion) {
    return (
      <Wrapper data-testid="employment-card">
        {organization && shouldBeNavigateToOrganization ? linkContent : content}
      </Wrapper>
    );
  }

  return (
    <OverviewCard testId="employment-card">
      {organization && shouldBeNavigateToOrganization ? linkContent : content}
    </OverviewCard>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const IconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const List = styled(MuiList)`
  padding: 0;
`;

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),

  ':last-child': {
    paddingBottom: 0,
  },
}));

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ChildStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const EmploymentOrganizationNameIcon = styled(MedicalServices)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '13.33px',
  height: '13.33px',
}));

const TextWithEllipsis = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'widthSizeInPixel',
})<{ widthSizeInPixel?: string }>(({ widthSizeInPixel }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: widthSizeInPixel ?? '80%',
}));
