import { Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { OrganizationData, TextHighlighter } from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getExpandNodeIds,
  OrganizationHierarchyData,
} from '@/services/organization';

interface OrganizationHierarchyTreeDataBlockProps {
  setSelectedOrganization: (organization: OrganizationData) => void;
  organization: OrganizationData;
  hierarchy: OrganizationHierarchyData;
  searchQuery: string;
}

export const OrganizationHierarchyTreeDataBlock = ({
  setSelectedOrganization,
  hierarchy,
  searchQuery,
}: OrganizationHierarchyTreeDataBlockProps) => {
  const { t } = useTranslation();

  const searchQueryArray = searchQuery ? searchQuery.split(' ') : [];

  const [expandedNodes, setExpandedNodes] = useState<(string | undefined)[]>([
    hierarchy.node.id,
  ]);

  useEffect(() => {
    if (searchQuery) {
      const expendIds = getExpandNodeIds(hierarchy, searchQuery);
      const nodes = [hierarchy.node.id, ...expendIds];
      setExpandedNodes(nodes);
    }
  }, [searchQuery, hierarchy, setExpandedNodes]);

  const handleOrganizationClick = (org: OrganizationData) => {
    setSelectedOrganization(org);
  };

  const handleOnNodeSelect = (event: React.SyntheticEvent, nodeIds: string) => {
    const index = expandedNodes.indexOf(nodeIds);
    const currentExpanded = [...expandedNodes];
    if (index === -1) {
      currentExpanded.push(nodeIds);
    } else {
      currentExpanded.splice(index, 1);
    }
    setExpandedNodes(currentExpanded);
  };

  const renderTree = (data?: OrganizationHierarchyData, isFirst?: boolean) => {
    if (!data) {
      return null;
    }

    return (
      <StyledTreeItem
        key={data.node?.id ?? ''}
        itemId={data.node?.id ?? ''}
        isFirst={!!isFirst}
        label={
          <Typography variant="body1">
            {data.node?.name ? (
              <TextHighlighter
                testId="organization-hierarchy-name-text-highlighter"
                searchQueryArray={searchQueryArray}
                text={data.node?.name}
              />
            ) : (
              t('pages.organizationView.hierarchy.unknownOrganizationName')
            )}
          </Typography>
        }
        onClick={() => handleOrganizationClick(data?.node)}
      >
        {data.children.map((node) => renderTree(node, false))}
      </StyledTreeItem>
    );
  };

  return (
    // onNodeSelect in current version of tree view supports two methods signature from SingleSelectTreeViewProps | MultiSelectTreeViewProps
    // (event: React.SyntheticEvent, nodeIds: string) => void for SingleSelectTreeViewProps while
    // (event: React.SyntheticEvent, nodeIds: string[]) => void for MultiSelectTreeViewProps;
    // Disable tsc check to make it pass the compilation otherwise it would have run time failure
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SimpleTreeView
      data-testid="organization-hierarchy-tree"
      aria-label="customized"
      onClick={() => handleOnNodeSelect}
    >
      <>{renderTree(hierarchy, true)}</>
    </SimpleTreeView>
  );
};

const StyledTreeItem = styled(TreeItem, {
  shouldForwardProp: (prop) => prop !== 'isFirst',
})<{ isFirst: boolean }>(({ theme, isFirst }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  borderLeft: isFirst
    ? ''
    : `0.5px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
}));
