import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

type MobileSearchCardProps = {
  filterCard: ReactNode;
  onClickFilterCard: VoidFunction;
};

export const MobileSearchCard = ({
  filterCard,
  onClickFilterCard,
}: MobileSearchCardProps) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <MobileFilterCard data-testid="mobile-content-person-search-tab">
      {filterCard}
      {/* Add this Box to make the content not be overlap by apply button */}
      <VirtualBox />
      <SubmitButtonWrapper>
        <SubmitButton
          sx={{ flex: 1 }}
          variant="contained"
          onClick={onClickFilterCard}
        >
          {t('components.globalSearch.filter.apply')}
        </SubmitButton>
      </SubmitButtonWrapper>
    </MobileFilterCard>
  );
};

const MobileFilterCard = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    // Add this style to make sure dialog content in mobile always be full screen, avoid ui error when content is too short
    minHeight: '100vh',
  },
}));

const VirtualBox = styled(Box)({
  height: 36,
});

const SubmitButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  display: 'flex',

  width: `calc(100% - ${theme.spacing(8)})`,
  backgroundColor: theme.palette.background.paper,
  padding: `${theme.spacing(2)} 0px`,
}));

const SubmitButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`;
