import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';

type MobileDoubleListDialogContentProps = {
  titleForAvailableItem: string;
  availableItemView: NonNullable<ReactNode>;
  titleForSelectedItem: string;
  selectedItemView: NonNullable<ReactNode>;
};
export const MobileDoubleListDialogContent = ({
  titleForAvailableItem,
  availableItemView,
  titleForSelectedItem,
  selectedItemView,
}: MobileDoubleListDialogContentProps) => {
  const { t } = useTranslation();
  const [isSelectedExpanded, setIsSelectedExpanded] = useState(false);
  const toggleViewSelectedItems = () => {
    setIsSelectedExpanded((prevState) => !prevState);
  };

  return (
    <ContentWrapper>
      <ViewWrapper isExpanded={isSelectedExpanded} isSelectedView={true}>
        <OverviewAccordionBlock
          title={t(titleForSelectedItem)}
          expanded={isSelectedExpanded}
          isFullHeight={true}
          onChange={toggleViewSelectedItems}
        >
          {selectedItemView}
        </OverviewAccordionBlock>
      </ViewWrapper>
      <ViewWrapper isExpanded={!isSelectedExpanded} isSelectedView={false}>
        <OverviewAccordionBlock
          title={t(titleForAvailableItem)}
          expanded={!isSelectedExpanded}
          isFullHeight={true}
          onChange={toggleViewSelectedItems}
        >
          {availableItemView}
        </OverviewAccordionBlock>
      </ViewWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

type WrapperStyleProps = {
  isExpanded: boolean;
  isSelectedView: boolean;
};

const ViewWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isExpanded' && prop !== 'isSelectedView',
})<WrapperStyleProps>(({ isExpanded, isSelectedView }) => ({
  '& .MuiCollapse-root.MuiCollapse-entered': {
    height: '90% !important',
    overflowY: 'auto',
  },

  height: '100%',
  overflowY: 'hidden',
  //reduce space between two summary tabs
  flex: isExpanded ? (isSelectedView ? 4 : 9) : 1,
}));
