import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { add, sub } from 'date-fns';
import { useState } from 'react';

import { MobileCalendarActionBar } from './MobileCalendarActionBar';

type MobileCalendarProps = {
  selectedDate: Date;
  onChangeDate: (date: Date | null) => void;
};

export const MobileCalendar = ({
  selectedDate,
  onChangeDate,
}: MobileCalendarProps) => {
  const [calendarDate, setCalendarDate] = useState<Date | null>(selectedDate);

  return (
    <>
      <MobileDatePicker
        value={calendarDate}
        format="PPPP" // TODO: Change it to a system-wide format when available
        minDate={sub(new Date(), { years: 6 })}
        maxDate={add(new Date(), { years: 5 })}
        slots={{
          textField: (params) => <StyledTextField {...params} />,
          actionBar: MobileCalendarActionBar,
        }}
        onChange={setCalendarDate}
        onAccept={onChangeDate}
      />

      <Stack justifyContent="center">
        <CalendarTodayIcon color="primary" />
      </Stack>
    </>
  );
};

const StyledTextField = styled(TextField)({
  display: 'none',
  '.MuiDialogContent-root &': {
    display: 'block',
  },
});
