import { CardContent, CardHeader, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CardPaddingStyleProps } from './ActivityDetailTypes';

export const StyledCardHeader = styled(CardHeader)<CardPaddingStyleProps>(
  ({ theme, padding }) => ({
    paddingTop: theme.spacing(padding),
    paddingInline: theme.spacing(padding),

    '.MuiCardHeader-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  })
);

export const CardHeaderStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
}));

export const ActivityTypeStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const StyledCardContent = styled(CardContent)<CardPaddingStyleProps>(
  ({ theme, padding }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    padding: theme.spacing(padding),
    paddingTop: theme.spacing(2),

    color: theme.palette.text.disabled,
  })
);
