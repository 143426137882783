import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type OverviewStaticBlockProps = {
  title: string;
  subtitle?: string | ReactNode;
  spacing?: 'dense' | 'spacious';
  children: ReactNode;
  testId?: string;
};

export const OverviewStaticBlock = ({
  title,
  subtitle,
  spacing = 'spacious',
  children,
  testId,
}: OverviewStaticBlockProps) => {
  return (
    <StyledCard data-testid={testId}>
      <StyledCardHeader
        customSpacing={spacing}
        title={
          <TitleWrapper>
            <Typography variant="subtitle2">{title}</Typography>
            {subtitle && typeof subtitle === 'string' ? (
              <Typography variant="subtitle2">{subtitle}</Typography>
            ) : (
              <>{subtitle}</>
            )}
          </TitleWrapper>
        }
      />

      <StyledCardContent customSpacing={spacing}>{children}</StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'customSpacing',
})<{ customSpacing: 'dense' | 'spacious' }>(({ theme, customSpacing }) => ({
  padding: customSpacing === 'dense' ? theme.spacing(2) : theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

const StyledCardHeader = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== 'customSpacing',
})<{ customSpacing: 'dense' | 'spacious' }>(({ theme, customSpacing }) => ({
  padding: customSpacing === 'dense' ? theme.spacing(2) : theme.spacing(3),
  paddingBottom: 0,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
