import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Button as MuiButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

type OverviewAccordionBlockProps = {
  children: NonNullable<ReactNode>;
  expanded?: boolean;
  onChange?: (event: SyntheticEvent, expanded: boolean) => void;
  title: string;
  numberOfItems?: number;
  viewAllHandler?: () => void;
  testId?: string;
  isFullHeight?: boolean;
};

export const OverviewAccordionBlock = ({
  children,
  expanded,
  onChange,
  numberOfItems,
  testId,
  title,
  viewAllHandler,
  isFullHeight = false,
}: OverviewAccordionBlockProps) => {
  const { t } = useTranslation();
  const fullTitle = numberOfItems ? `${title} (${numberOfItems})` : title;

  return (
    <StyledAccordion
      expanded={expanded}
      isFullHeight={isFullHeight}
      data-testid={testId}
      onChange={onChange}
    >
      <AccordionSummaryStyle
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography variant="subtitle2">{fullTitle}</Typography>
      </AccordionSummaryStyle>
      <AccordionDetails>
        {children}
        {viewAllHandler && (
          <Button variant="outlined" onClick={viewAllHandler}>
            {t('pages.interaction.common.viewAll')}
          </Button>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

type AccordionStyleProps = {
  isFullHeight: boolean;
};

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'isFullHeight',
})<AccordionStyleProps>(({ isFullHeight }) => ({
  height: isFullHeight ? '100%' : 'auto',
}));

const AccordionSummaryStyle = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingTop: 0,
}));

const Button = styled(MuiButton)`
  text-transform: uppercase;
`;
